import React from "react";
import { SEO, PageLayout, Image, InternalLink } from "@bluefin/components";
import { Grid, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class FoodTruckPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout hero={null}>
          <Image
            src={
              "https://fisherman.gumlet.io/public/f5800c80-941a-45de-9267-7716e178c9d2/2_BUBakeA.png"
            }
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"center"}>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"left"}
                centered={true}
                column={"2"}
              >
                <Grid.Row stretched={true} verticalAlign={"middle"}>
                  <Grid.Column width={8} textAlign={"left"}>
                    <p>
                      Hey there! Are you ready for a dining experience like no
                      other? Then you've come to the right place. Cosmic Burrito
                      Food Truck is all about serving up delicious burritos and
                      tacos with flavors inspired by cuisines from around the
                      world. We always use fresh ingredients and have vegetarian
                      options available, so there's something for everyone.
                    </p>
                    <p>
                      At Cosmic Burrito, we believe in making your meal an
                      unforgettable experience. Our focus is on bold flavors in
                      every dish, and we put a lot of love and care into each
                      one to make sure it's the best it can be. Plus we've got
                      ice-cold drinks to quench your thirst.
                    </p>
                    <p>
                      So what are you waiting for? Whether you're looking for a
                      casual lunch with friends or a fun night out, Cosmic
                      Burrito Food Truck is here for you. Check out our events
                      page to see where we'll be next and come hang out with us.
                      We can't wait to bring you the flavors of the cosmos!
                    </p>
                    <Button
                      primary={true}
                      size={"medium"}
                      to={"/catering/"}
                      as={InternalLink}
                    >
                      Catering
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Image
                      size={"big"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/f5800c80-941a-45de-9267-7716e178c9d2/312429518_542302371229340_8703332292245856183_n.jpg"
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Food Truck" }) {
      title
    }
  }
`;
